import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../store/userContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";

const SignInPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false); // new state variable for Snackbar
  const [error, setError] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const navigate = useNavigate();
  const user = useUser(); // Get user and setUser from the user context
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      // Send a POST request to the server
      const data = await axios.post("https://support.khalid.top/login", {
        email: email,
        password: password,
      });
      if (data.data.success) {
        const result = data.data.user;
        console.log(result);
        user.updateUser({
          id: result.id,
          name: result.name,
          email: result.email,
          phone_number: result.phone_number,
          isAdmin: result.isAdmin,
        });
        console.log(user);
        navigate("/");
      } else {
        setError(data.data.message);
      }
    } catch (error) {
      if (error.response) {
        setError(
          "حدث خطأ أثناء تسجيل الدخول: " + error.response.data.message
        );
      } else {
        setError("حدث خطأ أثناء تسجيل الدخول: " + error.message);
      }
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="text-center m-5-auto" dir="rtl">
      <h2>تسجيل الدخول إلينا</h2>
      <form onSubmit={handleLogin}>
        <p>
          <label>البريد الإلكتروني:</label>
          <br />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            required
          />
        </p>
        <p>
          <label>كلمة المرور:</label>

          <br />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            required
          />
        </p>
        <p>
          <button id="sub_btn" type="submit" disabled={loading}>
            {loading ? "جارٍ تسجيل الدخول..." : "تسجيل الدخول"}
          </button>
        </p>
      </form>
      <footer
        style={{ backgroundColor: "rgba(128, 128, 128, 0.5)", padding: "0 5px" }}
      >
        <p style={{ color: "#000" }}>
          الزيارة الأولى؟{" "}
          <Link to="/register" style={{ color: "#000" }}>
            إنشاء حساب
          </Link>
          .
        </p>
        <p>
          <Link to="/" style={{ color: "#000" }}>
            العودة إلى الصفحة الرئيسية
          </Link>
          .
        </p>
      </footer>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SignInPage;