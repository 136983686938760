import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useUser } from "../../store/userContext";
import AddTicketForm from "./ui/AddTicket";
import TicketList from "./ui/TicketList";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import TipsAndTricks from "./ui/TipsAndTricks";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";

const StyledAppBar = styled(AppBar)({
  flexGrow: 1,
});

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const StyledBox = styled(Box)({
  padding: "16px",
  minHeight: "80vh",
  backgroundColor: "#e0e0e0", // light grey color
});

export default function HomePage() {
  const user = useUser();
  const handleLogout = () => {
    user.updateUser(null);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box width={"100%"} dir="rtl">
      <StyledAppBar position="static">
        <StyledToolbar>
          <Typography variant="h6">
            مرحبا بك في تطبيقنا{" "}
            <Typography
              variant="h5"
              component="span"
              sx={{ color: "#B0B0B0" }}
            >
              {user.user.name}
            </Typography>
          </Typography>
          <Button
            component={RouterLink}
            to="/"
            onClick={handleLogout}
            variant="contained"
            color="secondary"
            startIcon={<LogoutIcon />}
          >
            تسجيل الخروج
          </Button>
        </StyledToolbar>
      </StyledAppBar>
      <StyledBox>
        <Typography variant="h4" component="h2" gutterBottom>
          مرحبا، {user.user.name}! هل أنت جاهز لإدارة تذاكرك؟
        </Typography>
        <Button
          onClick={openModal}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          فتح نموذج التذكرة
        </Button>
        {isModalOpen && <AddTicketForm onClose={closeModal} />}
        <TicketList closeModal={isModalOpen} />
        <TipsAndTricks />
      </StyledBox>
    </Box>
  );
}