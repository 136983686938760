import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { UserProvider } from "./store/userContext";

const rootElement = document.createElement("div");
document.body.appendChild(rootElement);

ReactDOM.createRoot(rootElement).render(
    <UserProvider>
    
    <App />
  </UserProvider>
);
