import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../store/userContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import "../../App.css";

export default function SignUpPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    agree: true,
  });
  const user = useUser();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://support.khalid.top/register", {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        phone_number: formData.phone,
      });

      if (response.data.success) {
        user.updateUser({
          name: response.data.user.name,
          email: response.data.user.email,
          id: response.data.user.id,
          phone_number: response.data.user.phone_number,
          isAdmin: response.data.user.isAdmin,
        });
        navigate("/");
      }
    } catch (error) {
      if (error.response) {
        setError("حدث خطأ أثناء التسجيل: " + error.response.data.message);
      } else {
        setError('فشل التسجيل. الرجاء المحاولة مرة أخرى.');
      }
      setOpen(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div className="text-center m-5-auto" dir="rtl">
      <h2>انضم إلينا</h2>
      <h5>أنشئ حسابك الشخصي</h5>
      <form onSubmit={handleSubmit}>
        <p>
          <label>الاسم</label>
          <br />
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </p>

        <p>
          <label>البريد الإلكتروني</label>
          <br />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </p>
        <p>
          <label>رقم الهاتف</label>
          <br />
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </p>
        <p>
          <label>كلمة المرور</label>
          <br />
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </p>
        <p>
          <button id="sub_btn" type="submit">
            تسجيل
          </button>
        </p>
      </form>
      <footer
        style={{
          backgroundColor: "rgba(128, 128, 128, 0.5)",
          padding: "0 5px",
        }}
      >
        {" "}
        <p>
          <Link to="/" style={{ color: "#000" }}>
            العودة إلى الصفحة الرئيسية
          </Link>
          .
        </p>
      </footer>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
}