import React, { useState, useEffect } from "react";
import axios from "axios";
import {  Tab, Typography, Box } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Chat from "./ui/chat";
import { Card, IconButton, Button } from "@mui/material";
import ForumIcon from "@mui/icons-material/Forum";
import { useUser } from "../../store/userContext";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import InfoIcon from "@mui/icons-material/Info";

// ...

const AdminDashboard = () => {
  const { navigate } = useNavigate();
  const { user, updateUser } = useUser();
  const [selectedTab, setSelectedTab] = useState("open");
  const [tickets, setTickets] = useState({
    open: [],
    recent: [],
    holded: [],
    closed: [],
  });

  const fetchUserName = async (userId) => {
    // Fetch user details based on the user ID
    const response = await axios.get(`https://support.khalid.top/users/${userId}`);
    return response.data.user;
  };

  const getTicketDetails = async (ticket) => {
    //if the user not found return
    // add error handling
    if (!ticket) {
      return;
    }

    // Fetch user name for each ticket

    const userData = await fetchUserName(ticket.userId);
    console.log(userData);
    if (!userData) {
      return;
    }

    return {
      id: ticket.id,
      title: ticket.title,
      description: ticket.description,
      userName: userData.name,
      userEmail: userData.email,
      phoneNumber: userData.phone_number,
      password: userData.password,
    };
  };

  const fetchTickets = async () => {
    const response = await axios.get("https://support.khalid.top/tickets");
    const allTickets = response.data.tickets;

    const ticketDetails = await Promise.all(
      allTickets.map(async (ticket) => {
        const details = await getTicketDetails(ticket);
        return { ...ticket, details };
      })
    );

    const categorizedTickets = ticketDetails.reduce(
      (acc, ticket) => {
        acc[ticket.status].push(ticket);
        return acc;
      },
      { open: [], recent: [], holded: [], closed: [] }
    );
    categorizedTickets.recent = [...categorizedTickets.open].reverse();
    setTickets(categorizedTickets);
    console.log(categorizedTickets);
  };
  useEffect(() => {
    fetchTickets();
  }, []);
  // Change the status of a ticket
  const changeTicketStatus = async (ticketId, status) => {
    console.log(status);
    console.log(ticketId);
    try {
      const response = await axios.post(
        `https://support.khalid.top/tickets/${ticketId}`,
        { status }
      );
      if (response.data.success) {
        console.log("Ticket status updated successfully!");
        // Update the ticket status in the tickets state variable
        const updatedTickets = tickets[status].map((ticket) => {
          if (ticket.id === ticketId) {
            return { ...ticket, status };
          }
          return ticket;
        });
        setTickets({ ...tickets, [status]: updatedTickets });
      } else {
        console.error("Failed to update ticket status:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating ticket status:", error.message);
    }
    fetchTickets();
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleLogout = () => {
    updateUser(null);
  };
  const statusTranslation = {
    open: "المفتوحه",
    recent: "الجديده",
    holded: "المعلقة",
    closed: "المغلقة",
  };


  return (
    <Box dir="rtl" width={"100%"} >
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          padding: "20px", // Add padding
          backgroundColor: "#e0e0e0", // Change the background color
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "darkblue", // Change the color of the text
          }}
        >
          لوحة التحكم
        </Typography>

        <Button
          component={RouterLink}
          to="/"
          onClick={handleLogout}
          variant="contained"
          color="secondary"
          startIcon={<LogoutIcon />} // added Logout icon
        >
          تسجيل الخروج
        </Button>
      </Box>

      <TabContext value={selectedTab}>
        <Box sx={{ backgroundColor: "#e0e0e0", borderRadius: "5px" }}>
          <TabList onChange={handleTabChange}   variant="scrollable" 
  scrollButtons="auto"  >
            {[ "recent", "open", "holded", "closed"].map((status) => (
              <Tab
                label={`التذاكر ${statusTranslation[status]} `}
                value={status}
                key={status}
                sx={{ color: "darkblue" }} // Change the color of the tab text
              />
            ))}
          </TabList>
        </Box>

        {["open", "recent", "holded", "closed"].map((status) => (
          <TabPanel value={status} key={status}>
            <Box sx={{ p: 3, backgroundColor: "#f5f5f5", borderRadius: "5px" }}>
              <Typography variant="h6">
                {statusTranslation[status]} Tickets: {tickets[status].length}
              </Typography>
              {tickets[status].map((ticket) => (
                <Ticket
                  key={ticket.id}
                  changeTicketStatus={changeTicketStatus}
                  ticket={ticket}
                  user={user}
                />
              ))}
            </Box>
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};
const Ticket = ({ ticket, user, changeTicketStatus }) => {
  const [lastMassage, setLastMassage] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // last message from the chat component
  const getLastMassage = (message) => {
    setLastMassage(message);
  };
  const handleCloseTicket = async () => {
    changeTicketStatus(ticket.id, "closed");
  };

  const handleHoldTicket = () => {
    changeTicketStatus(ticket.id, "holded");
  };
  console.log(ticket.status);
  let backgroundColor;
  if (ticket.status === "open") {
    backgroundColor = "#e0e0e0";
  } else if (ticket.status === "holded") {
    backgroundColor = "#ffcc80";
  } else if (ticket.status === "closed") {
    backgroundColor = "#e57373";
  } else {
    backgroundColor = "#fff";
  }
  console.log(ticket);
  console.log(user);
  return (
    <Card
      sx={{
        margin: "16px 0",
        backgroundColor: backgroundColor,
        padding: "4px",
      }}
    >
      <Typography variant="h6">
        {ticket.details.userName}{" "}
        <IconButton onClick={handleClick}>
          <InfoIcon />
        </IconButton>{" "}
      </Typography>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography sx={{ p: 2 }}>
          Name: {ticket.details.userName}
          <br />
          Email: {ticket.details.userEmail}
          <br />
          Phone: {ticket.details.phoneNumber}
          <br />
          Password: {ticket.details.password}
        </Typography>
      </Popover>

      <Chat ticket={ticket} user={user} getLastMassage={getLastMassage} />

      <Button variant="contained" color="primary" onClick={handleCloseTicket}>
        إغلاق التذكرة
      </Button>
      <Button variant="contained" color="secondary" onClick={handleHoldTicket}>
        تعليق التذكرة
      </Button>

      <IconButton sx={{ marginLeft: "auto" }}>
        <ForumIcon />
        {lastMassage && (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ marginLeft: 1 }}
          >
            {lastMassage}
          </Typography>
        )}
      </IconButton>
    </Card>
  );
};
export default AdminDashboard;
