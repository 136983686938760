import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Typography, Box } from "@mui/material";

import BackgroundImage from "../../assets/images/bg.png";

export default function LandingPage() {
    return (
      <Box
        dir="rtl"
        sx={{
          width: "100%",
          height: "100%",
          minHeight: "100vh",
          background: `url(${BackgroundImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          color: "#fff", // white text color
          padding: { xs: 0, sm: 2, md: 4, lg: 8, xl: 16 }, // responsive padding
          gap: { xs: 0, sm: 2, md: 4, lg: 8, xl: 16 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            bgcolor: "#ffffffcf",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <Typography variant="h2" color="primary">
            مرحبا بك في موقع مشكلات
          </Typography>
          <Box>
            <Typography variant="h5" color={"#000"}>
              حلك الشامل للتواصل السلس مع الدعم الفني.
            </Typography>
            <Typography variant="h6" color={"#000"}>
              افتح تذكرة /احصل على المساعدة، وتتبع طلباتك كلها في مكان واحد{" "}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              sx={{ m: 1, fontSize: "20px", padding: "10px 30px" }} // increased font size and padding
              component={RouterLink}
              to="/login"
            >
              تسجيل الدخول
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ m: 1, fontSize: "20px", padding: "10px 30px" }} // increased font size and padding
              component={RouterLink}
              to="/register"
            >
              تسجيل
            </Button>
          </Box>
        </Box>
      </Box>
    );
}