// Chat.js
import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Card,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import ChatIcon from "@mui/icons-material/Chat";
import Box from "@mui/material/Box";

const Chat = ({ user, ticket, getLastMassage }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (chatMessages.length > 0) {
      getLastMassage(chatMessages[chatMessages.length - 1].message);
    }
  }, [chatMessages]);

  const handleSendMessage = () => {
    if (!newMessage.trim()) {
      console.error("الرسالة لا يمكن أن تكون فارغة");
      return;
    }
    const message = { userId: user.id, message: newMessage };
    axios
      .post(`https://support.khalid.top/tickets/${ticket.id}/messages`, message)
      .then((response) => {
        setChatMessages([...chatMessages, response.data.message]);
        setNewMessage("");
      });
  };

  useEffect(() => {
    axios
      .get(`https://support.khalid.top/tickets/${ticket.id}/messages`)
      .then((response) => {
        setChatMessages(response.data.messages);
      });
  }, [open, ticket, newMessage]);

  return (
    <Card dir="rtl">
      <CardHeader
        avatar={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" color="primary" sx={{ ml: 1 }}>
              {ticket.id}
            </Typography>
            <ChatIcon />
          </Box>
        }
        onClick={() => setOpen(true)}
        title={
          <Typography variant="h5" align="right">
            {ticket.title}
          </Typography>
        }
        sx={{
          backgroundColor: "grey.100", // simple light grey background
          color: "text.primary", // primary text color
          "&:hover": {
            backgroundColor: "grey.200", // light grey background on hover
          },
        }}
        action={
          <IconButton
            sx={{
              color: "primary.main", // primary color
              "&:hover": {
                backgroundColor: "primary.light", // light primary color on hover
                color: "primary.dark", // dark primary color on hover
              },
            }}
          >
            <ChatIcon />
            <Typography variant="body2" color="primary" sx={{ ml: 1 }}>
              {chatMessages.length > 0 ? chatMessages.length : "0"}
            </Typography>
          </IconButton>
        }
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>دردشة</DialogTitle>
        <DialogContent>
          <List>
            {chatMessages.map((message) => (
              <ListItem
                key={Math.random()}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent:
                    message.userId === user.id ? "flex-end" : "flex-start",
                }}
              >
                <div style={{ display: "inline-block" }}>
                  <ListItemText
                    primary={message.message}
                    style={{
                      backgroundColor:
                        message.userId === user.id ? "#0a8d48" : "#f5f5f5",
                      color: message.userId === user.id ? "white" : "black",
                      borderRadius: "20px",
                      padding: "10px",
                      wordWrap: "break-word",
                    }}
                  />
                </div>
              </ListItem>
            ))}
          </List>
          <TextField
            variant="outlined"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            إغلاق
          </Button>
          <Button onClick={handleSendMessage} color="primary">
            إرسال
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Chat;
