import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LandingPage from "./components/pages/LandingPage";
import LoginPage from "./components/pages/LoginPage";
import RegisterPage from "./components/pages/RegisterPage";
import HomePage from "./components/pages/HomePage";
import AdminDashboard from "./components/pages/AdminDashboard"; // Import the new AdminDashboard component
import BackgroundImage from "./assets/images/bg.png";

import { useUser } from "./store/userContext";
import "./App.css";
import { Box } from "@mui/material";

export default function App() {
  const user = useUser();
  console.log(user);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        background: `url(${BackgroundImage})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Router>
        <Routes>
          {!user.user ? (
            <>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
            </>
          ) : (
            <>
              {!user.user.isAdmin ? ( // Check if the user is an admin
                <>
                  <Route path="/" element={<HomePage />} />
                </>
              ) : (
                <Route path="/" element={<AdminDashboard />} />
              )}
            </>
          )}
        </Routes>
      </Router>
    </Box>
  );
}
