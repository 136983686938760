// TipsAndTricks.js
import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const TipsAndTricks = () => {
    return (
        <Card sx={{ backgroundColor: "#f5f5f5", margin: "20px", borderRadius: "15px" }} dir="rtl">
            <CardContent>
                <Typography variant="h5" component="div" gutterBottom sx={{ color: "#008080" }}>
                    نصائح 
                </Typography>
                <Typography color="text.secondary" sx={{ fontSize: "18px", marginBottom: "10px" }}>
                    👉 نصيحة: تأكد من تقديم وصفات واضحة ومفصلة عند إنشاء التذاكر.
                </Typography>
                <Typography color="text.secondary" sx={{ fontSize: "18px", marginBottom: "10px" }}>
                    👉 هل تعلم؟ يمكنك التحقق من حالة التذاكر الخاصة بك في قسم قائمة التذاكر.
                </Typography>
                {/* أضف المزيد من النصائح حسب الحاجة */}
            </CardContent>
        </Card>
    );
};

export default TipsAndTricks;