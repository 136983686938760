// TicketList.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "../../../store/userContext";
import Chat from "./chat";
import { Card, Typography, IconButton } from "@mui/material";
import ForumIcon from "@mui/icons-material/Forum";

const TicketList = ({ closeModal }) => {
  const [tickets, setTickets] = useState([]);
  const { user } = useUser();

  useEffect(() => {
    axios.get(`https://support.khalid.top/tickets/${user.id}`).then((response) => {
      setTickets(response.data.tickets);
    });
  }, [user, closeModal]);

  return (
    <div dir="rtl">
      {tickets && tickets.length > 0 ? (
        tickets.map((ticket) => (
          <Ticket key={ticket.id} ticket={ticket} user={user} />
        ))
      ) : (
        <Typography variant="h6" color="error" align="center">
          لا توجد تذاكر متاحة بعد.
        </Typography>
      )}
    </div>
  );
};

export default TicketList;

const Ticket = ({ ticket, user }) => {
  const [lastMessage, setLastMessage] = useState("");
  // last message from the chat component
  const getLastMassage = (message) => {
    setLastMessage(message);
  };

  return (
    <Card sx={{ margin: "16px 0" }} dir="rtl">
      <Chat ticket={ticket} user={user} getLastMassage={getLastMassage} />
      <IconButton sx={{ marginRight: "auto" }}>
        <ForumIcon />
        {lastMessage && (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ marginRight: 1 }}
          >
            {lastMessage}
          </Typography>
        )}
      </IconButton>
    </Card>
  );
};
