// UserContext.js
import { createContext, useContext, useState ,  useEffect } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    useEffect(() => {
      // Check if user data is stored in localStorage
      const storedUser = localStorage.getItem("user");
        console.log(storedUser);
      if (storedUser) {
        // If user data is found, parse and set it
        setUser(JSON.parse(storedUser));
        console.log(JSON.parse(storedUser));
      }
    }, []);

 const updateUser = (userData) => {
   setUser(userData);
   // Store user data in localStorage
   console.log(userData);
   localStorage.setItem("user", JSON.stringify(userData));
   
 };

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
