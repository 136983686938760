// AddTicketForm.js
import React, { useState } from "react";
import axios from "axios";
import { useUser } from "../../../store/userContext";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const AddTicketForm = ({ onClose }) => {
  const { user } = useUser();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title.trim() || !description.trim()) {
      console.error("العنوان والوصف لا يمكن أن يكونا فارغين");
      return;
    }

    try {
      // Send a POST request to the server to add a new ticket
      const response = await axios.post("https://support.khalid.top/tickets", {
        title,
        description,
        userId: user.id, // Pass the user ID along with the ticket data
      });

      if (response.data.success) {
        console.log("تمت إضافة التذكرة بنجاح!");
        // Optionally, you can reset the form fields after a successful submission
        setTitle("");
        setDescription("");
        // Close the modal
        onClose();
      } else {
        console.error("فشل في إضافة التذكرة:", response.data.message);
      }
    } catch (error) {
      console.error("خطأ في إضافة التذكرة:", error.message);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md" dir="rtl">
      <DialogTitle>أضف تذكرة </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="العنوان"
            variant="outlined"
            fullWidth
            margin="normal"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="الوصف"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            إرسال
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddTicketForm;
